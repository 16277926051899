.contact {
    padding: 0rem 0 4rem;
    background-color: $color-white;
    // &__list {
    //     @include reset-list;
    //     margin-top: 4rem;
    //     display: flex;
    //     flex-wrap: wrap;
    //     justify-content: center;
    //     align-items: stretch;
    //     @include media('lg') {
    //         justify-content: center;
    //     }
    //     &__item {
    //         box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    //         border-radius: 10px;
    //         background-color: $color-white;
    //         width: 80%;
    //         margin-bottom: 6rem;
    //         @include media('lg') {
    //             width: 30%;
    //         }
    //     }
    // }

    &__img {
        &--logomegavida {
            max-width: 100%;
            width: 350px;
            margin-top: 4rem;
        }
    }
}