.gridimg {
    padding: 1rem 0;
    &__list {
        @include reset-list;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__item {
        flex: 0 0 calc(32.5%); /* Tamaño de las imágenes (33.33% con espacio entre ellas) */
        position: relative;
        padding-top: calc(21.6666%); /* Para hacer las imágenes cuadradas */
        overflow: hidden;
        margin-bottom: 1%;
        border-radius: 1rem;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        @include media('md') {
            flex: 0 0 calc(19.5%); /* Tamaño de las imágenes (33.33% con espacio entre ellas) */
            position: relative;
            padding-top: calc(13%); /* Para hacer las imágenes cuadradas */
        }
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}