//colore 
$color-alzarin: #e74c3c;
$color-sun-flower: #f1c40f;
$color-mine-shaft: #333333;
$color-gallery: #ecf0f1;
$color-white: #FFFFFF;
$color-black: #000000;
$color-mazarine-blue: #273c75;
$color-skirret-green: #44bd32;
$color-nanohanacha-gold: #e1b12c;
$color-mega-azul: #003366;
$color-mega-azul-claro: #0070C0;
$color-mega-gray: #95a5a6;
$color-mega-green: #00D92E;

$color-primary: $color-mega-azul-claro;
$color-secondary: $color-mega-azul;
$color-tertiary: $color-mega-green;
$color-font: $color-mine-shaft;